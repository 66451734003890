body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
}

.componentFirst {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  position: relative;
  overflow: hidden;
}

.r {
  background-color: red;
}

.componentSecond {
  width: 100%;
  max-width: 1450px;
  display: flex;
  margin: 0 50px;
}

@media only screen and (max-width: 670px) {
  .componentSecond {
    margin: 0 20px;
  }
  .main {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 450px) {
  .componentSecond {
    margin: 0 10px;
  }
}
