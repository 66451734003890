.BC {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.BCImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main {
  max-width: 550px;
  margin-bottom: 50px;
}

.mainTitle {
  margin-top: 50px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}

.mainSubTitle {
  margin-top: 8px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.customBox {
  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  border-radius: 8px;
  padding: 16px 24px;
  margin-top: 16px;
  max-width: 448px;
}

.customBoxText {
  max-width: 330px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.customBoxTextSmall {
  font-weight: 400;
}

.customBoxFooterText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 8px;
}

.mainGoals {
  margin-top: 24px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.mainListItem {
  display: flex;
  background: #fff;
  border-radius: 32px;
  align-items: center;
  margin-top: 16px;
  max-height: 56px;

  cursor: pointer;
}

.mainListItem:hover {
  background: #d8f4fe;
}

.mainListItemSelected {
  background: #d8f4fe;
  border: 2px solid #48a5dc;
  max-height: 52px;
}

.mainListItemIcon {
  padding: 8px 16px;
}

.mainListItemText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #163d59;
  max-width: 80%;
}

.mainListIcon path {
  fill: #163d59;
}

.mainContinue {
  display: flex;
  background: #48a5dc;
  border-radius: 32px;
  align-items: center;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #f7f5f2;
  padding: 16px;

  cursor: pointer;
}

.mainContinue:hover {
  transform: scale(1.01);
}

.mainContinueHide {
  opacity: 0;
  pointer-events: none;
}

.smallText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.smallBox {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 670px) {
  .mainListItem {
    max-height: 100px;
    height: 82px;
  }
  .mainListItemText {
    max-width: 75%;
  }
}
