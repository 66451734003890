.loaderTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
}

.loaderCustomText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.loaderImage {
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
}

.loaderBar {
  margin-top: 24px;
  width: 100%;
  height: 16px;
  background: #e3e3e3;
  opacity: 0.8;
  border-radius: 10px;
  position: relative;
}

.loaderBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loaderProgress {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #48a5dc;
  border-radius: 10px;
}

.loaderBarText {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 6px;
}
