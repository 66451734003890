.emailBold {
  font-weight: 600;
}

.email {
  max-width: 352px;
}

.emailInputTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f7f5f2;
  margin-top: 24px;
}

.emailInputContainer {
  background: #f7f5f2;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.emailInput {
  margin-left: 16px;
  border: none;
  background-color: transparent;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0d071b;
  width: 100%;
}

.emailInput::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0d071b;
  opacity: 0.5;
}

.emailInput:focus {
  outline: none;
}

.emailPersonalData {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 14px;
}

.emailPersonalDataIcon {
  min-width: 40px;
}

.emailPersonalDataText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #f7f5f2;
}
