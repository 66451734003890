.productCustom {
  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productCustomLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productSteps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  max-width: 327px;
  margin: 24px 24px 0;
}

.productStepsLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.productStepsNumber {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: #163d59;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productStepsNumberOutline {
  width: 44px;
  height: 44px;
  border-radius: 24px;
  background: #ffffff;
  border: 2px solid #163d59;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productStepsLine {
  height: 108px;
  width: 4px;
  background-color: #163d59;
}

.productStepsRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 428px;
}

.productStepsTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.productStepsBox {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #163d59;

  margin-top: 8px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 11px;
}

.productStepsItem {
  margin: 32px 0 40px;
}

.productTimer {
  background: #48a5dc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-top: 40px;
}

.productTimerhidden {
  opacity: 1;
  height: 32px;
  padding: 12px 24px;
  margin-top: 40px;
  display: none;
}

.productTimerhiddenShow {
  display: flex;
}

.is-sticky {
  position: fixed;
  top: 0px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  margin-top: 0;
}

.is-sticky-bottom {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  margin-top: 0;
  width: 100%;
  max-width: 343px;
  transform: translate(-50%);
  left: 50%;
}

.productTimerTimer {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.productBox {
  background: #0a0623;
  padding: 24px;
}

.productBoxSmallText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #d0d0d0;
  margin-top: 12px;
}

.productBoxOptions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.productBoxPlans {
  background: #163d59;
  padding: 24px;
}

.productCustomIconMargin {
  margin-bottom: 25px;
  min-width: 19px;
}

.productCustomIcon {
  min-width: 19px;
}

.productReview {
  background: #ffffff;
  box-shadow: 0px 2.97614px 22.321px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 16px;
  padding: 16px;
}

.productReviewTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0d071b;
  margin-top: 8px;
}

.productReviewText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0d071b;
  margin: 8px 0;
}

.productReviewName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #0d071b;
}

.productFAQHeader {
  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  padding: 24px 22px;
  margin-top: 48px;
}

.productFAQItem {
  padding: 20px 0;
  border-bottom: 1px solid #163d59;
}

.productFAQItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.productFAQTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.productFAQItemIconOpen {
  transform: rotate(-180deg);
  transition: transform 0.25s ease-in;
}

.productFAQItemIconClose {
  transition: transform 0.15s ease-in;
}

.productFAQItemShow {
  transition: max-height 0.25s ease-in;
  max-height: 200px;
  overflow: hidden;
}

.productFAQItemHidden {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.productFAQFooter {
  background: #020206;
  padding: 24px;
}

.productFAQFooterText {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #f7f5f2;
  opacity: 0.7;
  margin-top: 12px;
}
