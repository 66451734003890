.summaryBC {
  position: absolute;
  top: -50px;
  left: 0;
  min-height: 3350px;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

.BCSummaryBlur {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 45.32%, rgba(0, 0, 0, 0) 104.86%);
}

.summaryPopupBC {
  position: fixed;
  margin-top: -50px;
  width: 100%;
  height: 100%;
  background: #050511;
  opacity: 0.4;
  z-index: 5;
}

.summaryPopup {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  position: fixed;
  bottom: 10px;
  width: 320px;
  transform: translate(-50%);
  left: 50%;
  z-index: 99;
  padding: 24px 16px;
}

.summaryPopupClose {
  padding: 10px;
  margin-bottom: -10px;
  cursor: pointer;
}
.summaryPopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryPopupTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #0d071b;
  margin-bottom: 16px;
}

.summaryPopupHigh {
  background: #48a5dc;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 12px 24px 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.summaryPopupNormal {
  background: #eaca2d;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 12px 24px 6px;
}

.summaryPopupLow {
  background: #dd5047;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 4px;
  transform: scale(1.02);
}

.summaryPopupText {
  margin-top: 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0d071b;
}
.summaryTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 32px;
}

.summaryButtonContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  transform: translate(-50%);
  left: 50%;
  background: #0d071b;
  padding-bottom: 20px;
}

.productPlanOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 11px 24px 13px 16px;
  margin-top: 16px;
  position: relative;
  cursor: pointer;
}

.productPlanOptionSelected {
  border: 3px solid #48a5dc;
}

.productPlanOptionSpecial {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 80%;
  padding: 4px 0;
  transform: translate(-50%, -50%);

  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  border-radius: 2px;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.productPlanOptionLeft {
  display: flex;
  align-items: center;
  gap: 16px;
}

.productPlanOptionCircle {
  width: 20px;
  height: 20px;
  min-width: 20px;

  background: #ffffff;
  border: 2px solid #48a5dc;
  border-radius: 50%;
}

.productPlanOptionCircleSelected {
  width: 10px;
  height: 10px;
  min-width: 10px;

  background: #ffffff;
  border: 7px solid #48a5dc;
  border-radius: 50%;
}

.productPlanOptionPlanFor {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #163d59;
  max-width: 70px;
}

.productPlanOptionPlanForTime {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #020206;
  opacity: 0.6;
  margin-top: 6px;
}

.productPlanOptionOldPrice {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration-line: line-through;
  color: #020206;
  opacity: 0.6;
}

.productPlanOptionNewPrice {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #163d59;
  margin: 4px 0;
}

.productPlanOptionTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #020206;
}

.summarySubTitle {
  margin: 16px 0;
}

.summarySmallTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  padding-top: 8px;
  margin-bottom: 4px;
}

.summaryUnderLine {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #f7f5f2;

  cursor: pointer;
}

.summaryImage {
  max-height: 297px;
  max-width: 100%;
}

.summaryInfoBox {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(13, 7, 27, 0.1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
}

.summaryInfoBoxTitle {
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #48a5dc;
}

.summaryInfoBoxText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0d071b;
}

.summaryCustom {
  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  padding: 40px 24px;
  margin-top: 48px;
}

.summaryCustomTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 24px;
}

.summaryCustomItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 16px;
}

.summaryCustomItemText {
  margin-left: 8px;
}

.summaryBox {
  background: #0a0623;
  padding: 40px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.summaryBoxTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  max-width: 200px;
}

.summaryBoxImg {
  margin-top: 32px;
}

.summaryInfoIncludesTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 30px;
  text-align: center;
  color: #0d071b;
  margin-top: 16px;
  margin-bottom: 8px;
}

.summaryFooter {
  width: 100%;
  background-color: #0a0623;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.summaryFooterText {
  font-weight: 700;
}
