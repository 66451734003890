.quiz {
  max-width: 375px;
  margin-bottom: 50px;
  align-self: center;
  justify-self: center;
  width: 100%;
}

.BCImageBlur {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, #000000 9.01%, rgba(0, 0, 0, 0) 100.86%);
  backdrop-filter: blur(25px);
}

.quizHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quizHeaderSide {
  flex: 1;
  cursor: pointer;
}

.quizHeaderSide:hover {
  transform: scale(1.03);
}

.quizBar {
  margin-top: 24px;
  width: 100%;
  height: 5px;
  background: #e3e3e3;
  opacity: 0.8;
  border-radius: 10px;
  position: relative;
}

.quizBarProgress {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #48a5dc;
  border-radius: 10px;
}

.quizContainer {
  margin-top: 40px;
}

.quizTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 8px;
}
.quizSubTitleHighlighted {
  font-weight: 600;
}

.quizSubTitle {
  margin-top: 16px;
}

.quizCustom {
  background: linear-gradient(89.35deg, #5a0f88 9.56%, #360f88 97.05%);
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
}

.quizCustomTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 8px;
}

.quizOption {
  display: flex;
  background: #ffffff;
  border-radius: 32px;
  align-items: center;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;
  padding: 16px;
}

.quizOptionSelected {
  background: #d8f4fe;
  border: 2px solid #48a5dc;
  padding: 14px 16px;
}

.quizOptionText {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #163d59;
  margin-left: 20px;
}

.quizStatement {
  background: #163d59;
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 24px;
}

.quizStatementText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.quizTextInputContainer {
  width: calc(100% - 32px);
  min-height: 160px;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
}

.quizTextInput {
  border: none;
  width: 100%;
  height: 160px;
  max-width: 100%;
  resize: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(22, 61, 89, 1);
}

.quizTextInput ::placeholder {
  color: rgba(22, 61, 89, 0.5);
}

.quizTextInput:focus {
  outline: none;
}

.quizCustomBigText {
  margin-top: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.quizMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quizCustomBigTextMiddle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.quizMiddleImage {
  margin-bottom: 24px;
  height: 207px;
}

.quizCustomBigTextMiddle {
  margin-top: 16px;
  margin-bottom: 8px;
}

.quizEndingTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 10px;
}

.quizEndingItem {
  display: flex;
  margin-top: 8px;
}

.quizEndingText {
  margin-left: 8px;
}

.quizEndingIcon {
  width: 19px;
  min-width: 19px;
}

.quizSkipbutton {
  background: #d8f4fe;
  border-radius: 32px;

  display: flex;
  align-items: center;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #163d59;
  padding: 16px;

  cursor: pointer;
}
