.paymentBox {
  background: #ffffff;
  margin-top: 46px;
  padding: 24px;
}

.paymentTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0d071b;
}

.paymentPlan {
  background: #f7f5f2;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  margin-top: 16px;
  padding: 16px;
}

.paymentPlanTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentPlanText {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #0d071b;
  margin-bottom: 4px;
}

.paymentPlanTextDiscount {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff0000;
}

.paymentPlanLine {
  width: 100%;
  height: 1px;
  background: #d1d1d1;
  border-radius: 8px;
  margin: 12px 0;
}

.paymentPlanFooterText {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0d071b;
}

.paymentPlanFooter {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #0d071b;
}

.paymentOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentOptionItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentPlanSelectOption {
  width: 9px;
  height: 9px;
  min-width: 9px;
  border-radius: 50%;
  border: 6px solid #48a5dc;
}

.paymentIcon {
  max-width: 100%;
}

.paymentPlanSelectOptionUnselected {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #48a5dc;
}

.paymentPlanSelectText {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0d071b;
  margin-left: 12px;
}

.paymentPlanInputTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #0b1317;
}

.paymentPlanInputBox {
  width: calc(100% - 24px);
  padding: 10px 12px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  margin-bottom: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0d071b;
}

.paymentPlanInputBox:focus {
  outline: none;
}

.paymentButton {
  width: 100%;
  background: #97bdfb;
  border-radius: 5px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.paymentButtonActive {
  background: #48a5dc;
}

.paymentPopup {
  position: fixed;
  bottom: 20%;

  max-width: 375px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  z-index: 99;
}

.paymentPopupIcon {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.paymentPopupHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0d071b;
  text-align: center;
  margin-bottom: 24px;
}

.paymentPopupText {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #0d071b;
  text-align: center;
  margin-bottom: 24px;
}

.paymentTitlePlaceholder {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #b5b5b5;
}
